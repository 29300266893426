<template>
  <div class="main-container">
    <div class="menu">
      <div
        :class="selectedTabIndex === index ? 'menu-item-selected' : 'menu-item'"
        v-for="(element, index) in monitorTabOptions"
        v-bind:key="index"
        @click="() => switchTab(index)"
      >
        <p>{{ element }}</p>
      </div>
    </div>

    <LiveMetrics v-if="selectedTabIndex === 0" />
    <Health v-if="selectedTabIndex === 1" />
    <Datadog v-if="selectedTabIndex === 2" />

    <div class="external-links">
      <button @click="openAwsDashboard" class="info">
        {{ "Aws Dashboard" }}
      </button>
      <button @click="openDatadogDashboard" class="info">
        {{ "Datadog Dashboard" }}
      </button>
    </div>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import Datadog from "./components/Datadog.vue";
import LiveMetrics from "./components/LiveMetrics.vue";
import Health from "./components/Health.vue";

export default {
  name: "Monitor",
  components: {
    Datadog,
    LiveMetrics,
    Health,
  },
  setup() {
    const monitorTabOptions = ["Live Metrics", "Health", "Datadog Exports"];

    const store = useStore();
    const gameliftLink =
      "https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#dashboards:name=Gamelift;expand=true;start=PT12H";
    const datadogLing =
      "https://app.datadoghq.com/dashboard/ses-xzs-mya/gamelift?from_ts=1659369410102&to_ts=1659373010102&live=true";

    const selectedTabIndex = ref(0);

    onMounted(() => {
      store.dispatch("loader/loadingStatus", false);
    });

    onBeforeUnmount(() => {
      store.dispatch("loader/loadingStatus", true);
    });

    const openDatadogDashboard = () => {
      window.open(datadogLing, "_blank").focus();
    };

    const openAwsDashboard = () => {
      window.open(gameliftLink, "_blank").focus();
    };

    const switchTab = (index) => {
      selectedTabIndex.value = index;
    };

    return {
      openAwsDashboard,
      openDatadogDashboard,
      monitorTabOptions,
      selectedTabIndex,
      switchTab,
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.menu-item {
  cursor: pointer;
  border-bottom: 1px solid grey;
  font-size: 15px;
  padding: 10px 20px;
  min-width: 200px;
}

.menu-item:hover {
  background-color: lavender;
}

.menu-item-selected {
  border-bottom: 1px solid grey;
  font-size: 15px;
  background-color: khaki;
  min-width: 200px;
  padding: 10px 20px;
}
</style>