<template>
  <div class="health-panel">
    <div
      v-for="(api, index) in apis"
      :class="
        api.is_healthy ? 'health-panel-item-success' : 'health-panel-item'
      "
      v-bind:key="index"
    >
      <p>{{ api.name }}</p>
      <p>{{ `Health: ${api.is_healthy ? "OK" : "FAILED"}` }}</p>
      <p v-if="!api.is_healthy">{{ api.detail }}</p>
    </div>
  </div>
  <p>Last Updated at</p>
  <p>{{ moment(lastUpdate).format("DD/MM/YYYY HH:mm") }}</p>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import axios from "axios";
import moment from "moment";

export default {
  name: "MonitorHealth",
  setup() {
    const apis = ref([]);
    const lastUpdate = ref(0);
    let healthInterval;

    onMounted(() => {
        getHealthData();
        console.log("HEALTH MOUNT")
        healthInterval = setInterval(() => {
            getHealthData();
        }, 60000);
    });

    onBeforeUnmount(() => {
        console.log("HEALTH UNMOUNT")
        clearInterval(healthInterval);
    });

    const getHealthData = () => {
      axios
        .get("/api/monitor/health")
        .then((result) => {
          if (result.status === 200) {
            const data = result.data.data;
            lastUpdate.value = data["last_update"];
            let arr = [];
            Object.keys(data).forEach((element) => {
              if (element != "last_update") {
                arr.push({
                  name: element,
                  is_healthy: data[element].is_healthy,
                  message: data[element].detail,
                });
              }
            });
            apis.value = arr;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return {
      apis,
      moment,
      lastUpdate,
    };
  },
};
</script>

<style scoped>
.health-panel {
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px;
  border: 1px solid gray;
  border-radius: 10px;
}

.health-panel-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  height: 100px;
  width: 200px;
  margin: 5px;
  border-left: 10px solid red;
}

.health-panel-item-success {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  height: 100px;
  width: 200px;
  margin: 5px;
  border-left: 10px solid green;
}
</style>