<template>
  <div class="container">
    <h1>CCU</h1>
    <p class="ccutext">{{ currentCcu }}</p>

    <div class="chart-container">
      <div class="chart-filters">
        <div class="input-container">
          <p>Minimum Date</p>
          <DatePicker
            v-model="selectedMinDate"
            mode="dateTime"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="input"
                id="creation_date"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="input-container">
          <p>Maximum Date</p>
          <DatePicker
            v-model="selectedMaxDate"
            mode="dateTime"
            :model-config="{
              type: 'number',
              mask: 'DD/MM/YYYY HH:mm',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="input"
                id="creation_date"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </DatePicker>
        </div>
        <div class="input-container">
          <p>Granularity</p>
          <select class="input" v-model="selectedGranularity">
            <option
              v-for="option in granularityOptions"
              v-bind:key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="input-container">
          <Button
            :onClick="() => updateChart()"
            type="info"
            text="Update Chart"
            :loading="isLoading"
          />
        </div>
      </div>
      <div class="chart-container-inner">
        <canvas id="ccu-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { onMounted, onBeforeUnmount, ref } from "vue";
import "v-calendar/dist/style.css";
import Button from "../../../components/common/Button.vue";
import Chart from "chart.js";
import moment from "moment";

export default {
  name: "MonitorLiveMetrics",
  components: {
    Button,
  },
  setup() {
    const currentCcu = ref(0);
    let ccuInterval;

    const isLoading = ref(false);
    const selectedGranularity = ref(5);
    const selectedMinDate = ref(
      Math.floor(Date.now() / 1000) * 1000 - 1000 * 60 * 60 * 2
    );
    const selectedMaxDate = ref(Math.floor(Date.now() / 1000) * 1000);
    let chart;

    onMounted(() => {
      getCCUData();
      getCcuChartData();
      ccuInterval = setInterval(() => {
        getCCUData();
      }, 5000);
    });

    onBeforeUnmount(() => {
      clearInterval(ccuInterval);
    });

    const getCCUData = () => {
      axios
        .get("/api/monitor/ccu")
        .then((result) => {
          if (result.status === 200) {
            console.log("CCU is " + result.data.data);
            currentCcu.value = result.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getCcuChartData = () => {
      isLoading.value = true;
      axios
        .post("/api/monitor/ccu-chart", {
          max_date: Math.floor(selectedMaxDate.value / 1000),
          min_date: Math.floor(selectedMinDate.value / 1000),
          granularity: selectedGranularity.value,
        })
        .then((resp) => {
          if (resp.status === 200) {
            renderChart(resp.data.ccuChartData);
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const updateChart = () => {
      getCcuChartData();
    };

    const udpateChartValues = (data) => {
      chart.data.labels = data.map(x => {return moment(x.time).format('DD/MM/YYYY HH:mm')})
      chart.data.datasets[0].data = data.map(x => x.ccu)
      chart.update()
    }

    const renderChart = (data) => {
      if (chart !== undefined) {
        udpateChartValues(data)
      }
      const ctx = document.getElementById("ccu-chart").getContext('2d')
      chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map(x => {return moment(x.time).format('DD/MM/YYYY HH:mm')}),
          datasets: [
            {
              label: "ccu",
              data: data.map(x => x.ccu),
              backgroundColor: "rgba(156,73,93,.5)",
              borderColor: "rgba(156,73,93,.5)", 
              borderWidth: 3,
              yAxisId: 'default-y',
              xAxisId: 'default-x'
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                id: 'default-y',
                type: 'linear',
                ticks: { min: 0 }
              }
            ],
            xAxes: [
              {
                id: 'default-x',
                ticks: { maxTicksLimit: 20 }
              }
            ]
          }
        },
      });
    };

    return {
      isLoading,
      currentCcu,
      selectedGranularity,
      selectedMinDate,
      selectedMaxDate,
      updateChart,
      granularityOptions: [
        { text: "5 minutes", value: 5 },
        { text: "15 minutes", value: 15 },
        { text: "30 minutes", value: 30 },
        { text: "1 hour", value: 60 },
        { text: "3 hours", value: 180 },
        { text: "1 day", value: 1440 }
      ],
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 100px;
}

.chart-container {
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.chart-container-inner {
  width: 70%;
  height: 80%;
}

.chart-filters {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.input-container {
  margin-bottom: 20px;
}

.input {
  width: 200px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.ccutext {
  font-size: 36px;
}
</style>