<template>
  <div>
    <div class="dashboard">
      <div></div>
      <iframe
        src="https://app.datadoghq.com/graph/embed?token=a84a2f10d61ec967f13a23bc0c3ef78df6e3b98b0067ffc4c05e55dc9694e3ca&height=200&width=400&legend=true"
        width="400"
        height="250"
        frameborder="0"
      ></iframe>
      <div></div>
    </div>
    <div class="dashboard">
      <iframe
        src="https://app.datadoghq.com/graph/embed?token=cf95bfdceaab20e4704d19d964d281f5c7c8362a90fe85f83b483dbca575de71&height=200&width=400&legend=true"
        width="400"
        height="250"
        frameborder="0"
      ></iframe>
      <iframe
        src="https://app.datadoghq.com/graph/embed?token=26d7b7ecb1168a48adf8f08277466981df64c1df58b9f962186abb87532ca620&height=200&width=400&legend=true"
        width="400"
        height="250"
        frameborder="0"
      ></iframe>
      <iframe
        src="https://app.datadoghq.com/graph/embed?token=21ad4e7ffff45751a357b02de967998e58aed1e3132054ef838190510820e716&height=200&width=400&legend=true"
        width="400"
        height="250"
        frameborder="0"
      ></iframe>
      <iframe
        src="https://app.datadoghq.com/graph/embed?token=3010bf1621e2781ad2e498bac389c4edca5e88e53002df4a7cbe094bd5ff2701&height=200&width=400&legend=true"
        width="400"
        height="250"
        frameborder="0"
      ></iframe>
      <iframe
        src="https://app.datadoghq.com/graph/embed?token=05cc439025ff8948b7f7af8cce20f8e58dbeea457e412724599d335ed4c48cec&height=200&width=400&legend=true"
        width="400"
        height="250"
        frameborder="0"
      ></iframe>
      <iframe
        src="https://app.datadoghq.com/graph/embed?token=abfbc4806eb5eb812001cd4770bb39a9f46a0f88efac43e565f0ab7dd6a02e31&height=200&width=400&legend=true"
        width="400"
        height="250"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {}
</script>



<style scoped>
.dashboard {
  padding: 10px;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>